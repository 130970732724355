@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'LiberationSans';
  src: url('./fonts/LiberationSans-Regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LiberationSans';
  src: url('./fonts/LiberationSans-Italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'LiberationSans';
  src: url('./fonts/LiberationSans-Bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

.bg-trans {
  background: rgba(0,0,0,0.7);
}

.bg-main {
  text-align: center;
  background: url('bg-alt.jpg') top center;
}

.bg-iso {
  text-align: center;
  background: url('bg-iso.jpg') top center;
  background-size: cover;
}

.bg-ux {
  text-align: center;
  background: url('bg-ux.jpg') top center;
  background-size: cover;
}

.bg-code {
  background: url('bg-code.jpg') center center;
}

.bg-code {
  background: url('bg-code.jpg') center center;
}

.bg-iso {
  text-align: center;
  background: url('bg-iso.jpg') top center;
  background-size: cover;
}

.header {
  background: rgba(0,0,0,0.9);
}

.App-link {
  color: #61dafb;
}

.btn {
  @apply bg-black text-white font-bold border-2 focus:outline focus:outline-1 focus:outline-offset-3 px-xl py-base min-w-128 hover:border-primary;
  border-radius: 2px;
}

.btn:hover {
  border-color: white;
  border-bottom-color: #5DB1CB;
}

.btn.primary {
  @apply text-text bg-black border-white rounded-sm border hover:border-primary;
}


.main-heading {
  @apply border-b border-b-primary pb-xs mb-md text-white;
  text-shadow: 0 1px 0 #bbb;
}

.bg-aviva {
  background: rgb(255, 217, 0);
}

.bg-beacon {
  background: rgb(231, 40, 42);
}

@keyframes borderMove {
  0% {
    border-left-color: #FFF;
    border-top-color: #FFF;
    border-right-color: #FFF;
    border-bottom-color: #FFF;
  }
  25% {
    border-left-color: #5DB1CB;
    border-top-color: #FFF;
    border-right-color: #FFF;
    border-bottom-color: #FFF;
  }
  50% {
    border-left-color: #FFF;
    border-top-color: #5DB1CB;
    border-right-color: #FFF;
    border-bottom-color: #FFF;
  }
  75% {
    border-left-color: #FFF;
    border-top-color: #FFF;
    border-right-color: #5DB1CB;
    border-bottom-color: #FFF;
  }
  100% {
    border-left-color: #FFF;
    border-top-color: #FFF;
    border-right-color: #FFF;
    border-bottom-color: #5DB1CB;
  }
}

.border-animation {
  border-radius: 3px;
  border: 3px solid #fff;
  animation: borderMove 1.2s infinite, colorTransition 1.25s ease-in-out infinite;
}

.no-border-animation-hover:hover {
  animation: none;
  border-color: #5DB1CB;
}

.app-bg {
  background: url('app-bg.jpg') center center;
}

@keyframes fade {
  0% {
opacity: 1;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fade-image {
  opacity: 1;
  animation: fade 5.5s infinite;
}

.grecaptcha-badge {
  display: none !important;
}